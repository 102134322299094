<template>
    <section v-if="!loading">
        <!-- DESKTOP -->
        <v-container v-if="!isMobile()" fluid  class="my-10 py-10 mx-2 px-16">
            <v-row>
                <v-col cols="12" md="auto" class="text-uppercase text-h3 font-weight-black align-start justify-center text-start">
                    Portfólio
                </v-col>
                <v-col cols="12" md="9" class="text-high-emphasis align-center justify-center text-justify">
                    Damos a conhecer todas as nossas áreas de incidência, bem como os projetos que compõem cada uma. Aqui estão reunidas propostas desde 2019, até aos dias de hoje, em território nacional e no continente Africano.
                </v-col>
            </v-row>
            <v-row class="five-cols">
                <v-col
                    v-for="(item) in portfolio_data"
                    :key="item.id"
                >
                    <v-card class="rounded-xl point-cursor-card" @click="openCardInfo(item.id)">
                        <v-img
                            :lazy-src='require("@/assets/portfolio/" + item.img_root)'
                            :src='require("@/assets/portfolio/" + item.img_root)'
                            aspect-ratio="1"
                            class="bg-grey-lighten-2"
                            cover
                        >
                            <template v-slot:placeholder>
                                <v-row
                                    align="center"
                                    class="fill-height ma-0"
                                    justify="center"
                                >
                                    <v-progress-circular
                                        color="grey-lighten-5"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                        <v-overlay
                            v-model="overlay"
                            class="align-center justify-center text-white text-body-1 font-weight-black"
                            contained
                            persistent
                            width="50%"
                        >
                            {{item.title}}
                        </v-overlay>
                        </v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- DESKTOP -->

        <!-- MOBILE -->
        <v-container  fluid v-else>
            <v-row>
                <v-col cols="12" md="auto" class="text-uppercase text-h3 font-weight-black align-start justify-center text-start">
                    Portfólio
                </v-col>
                <v-col cols="12" md="9" class="text-high-emphasis align-center justify-center text-justify">
                    Damos a conhecer todas as nossas áreas de incidência, bem como os projetos que compõem cada uma. Aqui estão reunidas propostas desde 2019, até aos dias de hoje, em território nacional e no continente Africano.
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    v-for="(item) in portfolio_data"
                    :key="item.id"
                    cols="12"
                >
                    <v-card height="300px" class="rounded-xl point-cursor-card" @click="openCardInfo(item.id)">
                        <v-img
                            :lazy-src='require("@/assets/portfolio/" + item.img_root)'
                            :src='require("@/assets/portfolio/" + item.img_root)'
                            aspect-ratio="1"
                            class="bg-grey-lighten-2"                            
                            cover
                        >
                            <template v-slot:placeholder>
                                <v-row
                                    align="center"
                                    class="fill-height ma-0"
                                    justify="center"
                                >
                                    <v-progress-circular
                                        color="grey-lighten-5"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                        <v-overlay
                            v-model="overlay"
                            class="align-center justify-center text-white text-body-1 font-weight-black"
                            contained
                            persistent
                            width="50%"
                        >
                            {{item.title}}
                        </v-overlay>
                        </v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- MOBILE -->
    </section>
</template>
<script>
import portfolio from '@/portfolio-data/portfolio.json'

export default{
    name:"PorfolioView",
    data(){
        return{
            loading: true,
            overlay: true,
            portfolio_data: []
        }
    },
    beforeCreate(){
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });


    },
    mounted(){
        let object = portfolio.portfolio_data

        this.portfolio_data = object


        this.loading = false
    },
    methods:{
        isMobile() {
            if( screen.width <= 760 ) {
                return true;
            }
            else {
                return false;
            }
        },
        openCardInfo(item){
            this.$router.push('/' + this.$i18n.locale + '/portfolio/' + item)
        }
    }
}
</script>
<style>
.five-cols {
  display: grid !important;
  grid-template-columns: repeat(5, 1fr) !important;
  padding-bottom: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

.point-cursor-card{
    cursor: pointer;
}
</style>