<template>
    <section v-if="!loading">       
        <!-- DESKTOP -->
        <v-img
            class="d-none d-lg-block"
            style="position: relative; overflow: visible;"
            aspect-ratio="16/9"
            cover
            :src="url == null ? require('@/assets/CoverView/' + newCoverPath) : url"
        >
            <div class="ifos_box">
                <div class="text-white" style="width: 85%; margin: auto; display: block;">
                    A ESQUÉNIO, Estudos e Projetos de Engenharia, S.A. é uma empresa de Engenharia Civil pertencente ao grupo CONDURIL S.A., que compreende as vertente
                </div>
                <v-row justify="center" class="my-5 py-5 box_to_mobile">
                    <v-col cols="auto" v-for="(item, index) in cards1" :key="index" @click="goToPortfolio" style="cursor: pointer;">
                        <v-tooltip text="Ver mais" location="bottom">
                            <template v-slot:activator="{ props }">
                                <v-card v-bind="props" class="rounded-xl elevation-10 d-flex align-center justify-center bg-primary card_to_mobile" style="width: 100px; height: 100px;">
                                    <v-card-text class="text-white text-caption">
                                        {{item.text}}
                                    </v-card-text>
                                </v-card>
                            </template>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </div>
            <div class="img-bottomleft">
                <v-img                    
                    :src="require('@/assets/CoverView/Logo-Esquénio---branco---com-frase.png')"
                    cover
                />
            </div>       
        </v-img>
        <!-- DESKTOP -->




        <!-- MOBILE -->
        <v-img
            class="d-lg-none"
            style="position: relative; overflow: visible; height: 100vh;"
            aspect-ratio="16/9"
            cover
            :src="url == null ? require('@/assets/CoverView/' + newCoverPath) : url"
        >
            <div class="text-white font-weight-bold py-8 mx-4 px-4">
                A ESQUÉNIO, Estudos e Projetos de Engenharia, S.A. é uma empresa de Engenharia Civil pertencente ao grupo CONDURIL S.A., que compreende as vertente
            </div>      
            <v-row justify="center">
                <v-col cols="12" align="center" v-for="(item, index) in cards1" :key="index" @click="goToPortfolio" style="cursor: pointer;">
                    <v-tooltip text="Ver mais" location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-card v-bind="props" class="rounded-xl elevation-10 d-flex align-center justify-center bg-primary" width="35%" height="75px">
                                <v-card-text class="text-white text-caption">
                                    {{item.text}}
                                </v-card-text>
                            </v-card>
                        </template>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="center">
                    <v-img                    
                        :src="require('@/assets/CoverView/Logo-Esquénio---branco---com-frase.png')"
                        cover
                        width="50%"
                    />
                </v-col>
            </v-row>       
        </v-img>
        <!-- MOBILE -->
</section>
</template>
<script>
    export default{
        name:"CoverView",
        props:{
            fields:{
                typeof: Object,
                default: null
            }
        },
        data(){
            return{
            cards1:[
                    {
                        text: "Projeto",
                        icon:""
                    },
                    {
                        text: "Consultoria e Acessoria Técnica",
                        icon:""
                    },
                    {
                        text: "Fiscalização",
                        icon:""
                    },                {
                        text: "Construção de Obras",
                        icon:""
                    }
                ],
                coverViewFields: null,
                loading: true,
                url: null,
                newCoverPath: null
            }
        },
        mounted(){
            this.coverViewFields = this.fields
            this.newCoverPath = this.coverViewFields[this.$i18n.locale].coverView.imageCover
            this.loading = false
        },
        methods:{
            goToPortfolio(){
                this.$router.push('/' + this.$i18n.locale + '/portfolio')
            }
        }
    }
</script>
<style>
.h-100-lg{
    height: 100vh;
}
.img-bottomleft {
  position: absolute;
  bottom: 10vh;
  left: 80px;
  font-size: 18px;
  width: 500px;

  @media (max-width: 768px) {
    display: none;
    
  }
}

.ifos_box {
    width: 70vh; 
    position: absolute; 
    top: 100px; 
    right: 100px;

    @media (max-width: 768px) {
        text-align: center;
        top: 20px;
        right: 0;
        width: 100%; 
    }
}

.box_to_mobile {
    @media (max-width: 768px) {
        display: grid !important;
        grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
    }
}

.card_to_mobile {
    @media (max-width: 768px) {
        width: 90px !important;
        height: 90px !important;
    }
}
</style>