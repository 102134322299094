<template>
    <div class="burger-menu">
      <button class="burger-button" @click="toggleMenu">
        <span :class="{ 'open': isOpen }"></span>
        <span :class="{ 'open': isOpen }"></span>
        <span :class="{ 'open': isOpen }"></span>
      </button>
      <nav :class="{ 'open': isOpen }" class="menu">
        <tab @click="navigateAndClose('/' + $i18n.locale)" class="menu_item">{{ $t('menu.home') }}</tab>
        <tab @click="navigateAndClose('/' + $i18n.locale + '/about')" class="menu_item">{{ $t('menu.about') }}</tab>
        <tab @click="navigateAndClose('/' + $i18n.locale + '/portfolio')" class="menu_item">{{ $t('menu.portfolio') }}</tab>
        <tab @click="navigateAndClose('/' + $i18n.locale + '/contacts')" class="menu_item">{{ $t('menu.contacts') }}</tab>
      </nav>
    </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    navigateAndClose(route) {
      this.$router.push(route);
      this.isOpen = false; 
    },
  },
};
</script>
<style>

.burger-button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  padding: 0;
  z-index: 10;
  margin-right: 3rem;
}

.burger-button span {
  width: 30px;
  height: 3px;
  background: #fff;
  border-radius: 2px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.burger-button span.open:nth-child(1) {
  transform: rotate(45deg);
}

.burger-button span.open:nth-child(2) {
  opacity: 0;
}

.burger-button span.open:nth-child(3) {
  transform: rotate(-45deg);
}

.menu {
  visibility: hidden;
  display: flex;
  flex-direction: column !important;
  background: #0e2435;
  position: absolute;
  right: 0;
  width: 100%;
  align-items: center;
  padding: 1rem 1rem 2rem 1rem;
  margin-top: 1rem;
  gap: 2rem;
}

.menu tab {
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  width: 50%;
}

.menu.open {
  visibility: visible;
}

.menu_item:hover {
  border-bottom: 2px solid #fff;
}

</style>