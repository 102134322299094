<template>
  <v-container v-if="!loading" fluid class="ma-0 pa-0 red">

    <CoverView 
      :fields="fields"
      textColor="white"
    />

    <PresentationView />

    <ImageOnlyView />

    <ShareWithYou />


  </v-container>
</template>

<script>
import index from '@/locales/index.js'
import CoverView from '@/components/ui/CoverView.vue'
import PresentationView from '@/components/ui/PresentationView.vue'
import ImageOnlyView from '@/components/ui/ImageOnlyView.vue'
import ShareWithYou from '@/components/ui/ShareWithYou.vue'


export default {
  name: 'HomeView',
  components:{
    CoverView,
    PresentationView,
    ImageOnlyView,
    ShareWithYou
  },
  data(){
    return{
      fields: null,
      loading: true,
    }
  },
  watch:{
    '$route.params.place':{
      immediate: true,
      handler(val){
        
        this.goTo(val)
        
        //this.$refs['sections'][parseInt(val)].scrollIntoView({ behavior: 'smooth' })
      }
    }
  },
  async mounted(){
    this.fields = index
    
    this.loading = false

    if(this.$route.params.place != undefined){
      

    await this.delay(100)
      this.goTo(this.$route.params.place)
    }
  },
  methods:{
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    goTo(val){
      if(this.$refs[val] == undefined)
          return

        if(this.$refs[val][0] == undefined)
          return

        if(this.$refs[val][0].$el == undefined)
          return

        this.$refs[val][0].$el.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>
<style>
@media screen and (min-width: 1264px) {
  #price {
    margin-bottom: -40vh;
  }

  .min-h-100-lg {
    min-height: 100dvh;
  }
}
</style>