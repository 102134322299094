<template>
    <section v-if="!loading" class="d-flex align-center justify-center bg-info" style="padding-top: 8rem">
        <div class="d-none d-lg-block">
            <div style="width: 75%; margin: auto;justify-content: center; align-items: center;">
                <v-row class="text-secondary">
                    <v-col cols="12">
                        <h1>A ESQUÉNIO está norteada para intervir no domínio da <br/>Engenharia Civil, abrangendo assim na sua intervenção<br/>nas seguintes áreas:</h1>
                    </v-col>                    
                </v-row>
            </div>


            <v-row justify="center">
                <v-col cols="auto" v-for="(item, index) in cards1" :key="index">
                    <div class="pre-box d-flex align-center justify-center">
                        <div class="ribbon d-flex align-center justify-center">
                            <v-icon size="x-large" color="white">{{item.icon}}</v-icon>
                        </div>
                        <v-card class="rounded-xl elevation-10 d-flex align-center justify-center bg-primary card_size">
                            <v-card-text class="text-white">
                                {{item.text}} 
                            </v-card-text>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto" v-for="(item, index) in cards2" :key="index">
                    <div class="pre-box d-flex align-center justify-center">
                        <div class="ribbon d-flex align-center justify-center">
                            <v-icon size="x-large" color="white">{{item.icon}}</v-icon>
                        </div>
                        <v-card class="rounded-xl elevation-10 d-flex align-center justify-center bg-primary card_size" >
                            <v-card-text class="text-white">
                                {{item.text}}
                            </v-card-text>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
        </div>



        <!-- MOBILE -->
        <div class="d-lg-none">
            <div style="width: 75%; margin: auto;justify-content: center; align-items: center;">
                <v-row class="text-secondary">
                    <v-col cols="12">
                        <h1>A ESQUÉNIO está norteada para intervir no domínio da <br/>Engenharia Civil, abrangendo assim na sua intervenção<br/>nas seguintes áreas:</h1>
                    </v-col>                    
                </v-row>
            </div>


            <v-row justify="center" class="mt-15">
                <v-col cols="12" align="center" v-for="(item, index) in cards1" :key="index">
                    <div class="pre-box d-flex align-center justify-center">
                        <div class="ribbon d-flex align-center justify-center">
                            <v-icon size="x-large" color="white">{{item.icon}}</v-icon>
                        </div>
                        <v-card class="rounded-xl elevation-10 d-flex align-center justify-center bg-primary card_size">
                            <v-card-text class="text-white">
                                {{item.text}} 
                            </v-card-text>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" align="center" v-for="(item, index) in cards2" :key="index">
                    <div class="pre-box d-flex align-center justify-center">
                        <div class="ribbon d-flex align-center justify-center">
                            <v-icon size="x-large" color="white">{{item.icon}}</v-icon>
                        </div>
                        <v-card class="rounded-xl elevation-10 d-flex align-center justify-center bg-primary card_size" >
                            <v-card-text class="text-white">
                                {{item.text}}
                            </v-card-text>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
        </div>




    </section>
</template>
<script>
export default{
    name:"PresentationView",
    data(){
        return{
            loading: true,
            cards1:[
                {
                    text: "Projeto de Estruturas de Betão Armado e Pré-esforçado",
                    icon:"mdi-chart-box"
                },
                {
                    text: "Projeto de Estruturas Metálicas",
                    icon:"mdi-soldering-iron"
                },
                {
                    text: "Projeto Geotécnico",
                    icon:"mdi-earth"
                },                
                {
                    text: "Vias de Comunicação",
                    icon:"mdi-road-variant"
                }
            ],
            cards2:[
                {
                    text: "Estruturas Provisórias (cimbres, contenções, etc...)",
                    icon:"mdi-find-replace"
                },
                {
                    text: "Redes Prediais de Abastecimento de Água, Saneamento e Pluviais",
                    icon:"mdi-waves"
                },
                {
                    text: "Redes de Gás",
                    icon:"mdi-fire"
                },
                {
                    text: "Projeto de aplicação de Pré-esforço em sistemas de pós-tensão",
                    icon:"mdi-thermometer"
                },
                {
                    text: "Projeto de elevação provisória de obras de arte e outras estruturas",
                    icon:"mdi-palette"
                },
            ]
        }
    },
    mounted(){
        this.loading = false
    }
}
</script>
<style>
.pre-box{
    width: 200px; 
    height: 200px;
    position: relative;
}

.ribbon{
    position: absolute;
    top: 3px;
    left: 3px;
    width: 50px;
    height: 50px;
    z-index: 999;
    background-color: #0e2435;
    border-radius: 100%;

    @media (max-width: 768px) {
        left: -20px
    }
} 

.card_size {
    width: 150px;
    height: 150px;

    @media (max-width: 768px) {
        width: 300px;
    }
}
</style>