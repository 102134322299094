<template>
    <v-footer 
        color="secondary"
        class="text-center d-flex flex-column justify-center" style="width: 100%; height: 8rem"
    >
     
      <!-- DESKTOP -->
      <div class="d-none d-lg-block">
        <tab @click="$router.push('/' + $i18n.locale)" class="menu_item">{{ $t('menu.home') }}</tab>
        <tab @click="$router.push('/' + $i18n.locale + '/about')" class="menu_item">{{ $t('menu.about') }}</tab>
        <tab @click="$router.push('/' + $i18n.locale + '/portfolio')" class="menu_item">{{ $t('menu.portfolio') }}</tab>
      </div>
  
      <div class="d-none d-lg-block mt-5">
        <tab @click="$router.push('/' + $i18n.locale + '/contacts')" class="menu_item">{{ $t('menu.contacts') }}</tab>
        <a href="#" @click="openLink('https://www.linkedin.com/company/esquénio-estudos-e-projetos-de-engenharia-s-a')">
          <i class="fab fa-lg fa-linkedin" style="color: white;"></i>
        </a>
      </div>
      <!-- DESKTOP -->

      <!-- MOBILE -->
      <div class="d-lg-none">        
        <a href="#" @click="openLink('https://www.linkedin.com/company/esquénio-estudos-e-projetos-de-engenharia-s-a')">
          <i class="fab fa-lg fa-linkedin" style="color: white;"></i>
        </a>
      </div>
      <!-- MOBILE -->


      

    </v-footer>
</template>
<script>
export default{
  methods:{
    openLink(l){
      window.open(l, '_blank')
    }
  }
}
</script>
<style scoped>
  .menu_item {
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    padding-inline: 2rem;
  }
</style>