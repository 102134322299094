<template>
    <v-app-bar fixed hight="5vh" v-if="!loading" color="primary">
        
        <img @click="$router.push('/')" class="pointer ml-8" src="@/assets/logo.png" height="25">

        <v-spacer></v-spacer>
        <v-tabs right :class="isMobile() ? 'd-none' : 'd-lg-block'" :value="$route.path">
            <v-tab :to="'/' + $i18n.locale">{{ $t('menu.home') }}</v-tab>
            <v-tab :to="'/' + $i18n.locale + '/about'">{{ $t('menu.about') }}</v-tab>
            <v-tab :to="'/' + $i18n.locale + '/portfolio'">{{ $t('menu.portfolio') }}</v-tab>
            <v-tab :to="'/' + $i18n.locale + '/contacts'">{{ $t('menu.contacts') }}</v-tab>
        </v-tabs>

        <v-row :class="isMobile() ? 'd-flex justify-end' : 'd-none'">
            <BurguerMenu />
        </v-row>
        
    </v-app-bar>
</template>
<script>
import BurguerMenu from './BurguerMenu.vue'

export default{
    components: {
        BurguerMenu
    },
    data(){
        return{
            loading: true
        }
    },
    beforeCreate(){
        window.scrollTo(0,0);
    },
    mounted(){
        if(this.$route.params.language != undefined){
            this.$i18n.locale = this.$route.params.language
        }

        this.loading = false

    },
    methods:{
        goTo(place, home = true){
            if(home)
                this.$router.push('/' + this.$i18n.locale + '/home/' + place)
            else
                this.$router.push('/' + this.$i18n.locale + '/' + place)
        },
        changeLanguage(l){            
            this.$emit('switchLocale', l)
        },
        isMobile() {
            if( screen.width <= 760 ) {
                return true;
            }
            else {
                return false;
            }
        },  
    }
}
</script>
<style scoped>
.v-app-bar {
  overflow: visible !important; 
  position: relative; 
  z-index: 10; 
}

.pointer {
    cursor: pointer;
}
</style>