<template>
  <router-view></router-view>
</template>
<script>
// SEO - https://www.google.com/search?q=vue+js+3+seo&sca_esv=567555228&sxsrf=AM9HkKkDuaYr9q1gUEmdVu2pZU2BjQDrVg%3A1695378888751&ei=yG0NZYiVLbqTkdUPkK2YqAw&oq=vuejs+seo&gs_lp=Egxnd3Mtd2l6LXNlcnAiCXZ1ZWpzIHNlbyoCCAUyDBAjGIoFGBMYgAQYJzIGEAAYFhgeMggQABgWGB4YCjIIEAAYFhgeGAoyBhAAGBYYHjIIEAAYFhgeGAoyCBAAGAgYHhgNMgYQABgWGB5IuTBQ8wZYhxVwAXgBkAEAmAGHAaABiQiqAQMwLjm4AQPIAQD4AQHCAgoQABhHGNYEGLADwgIHECMYigUYJ8ICBBAjGCfCAhEQLhiKBRixAxiDARjHARjRA8ICCxAAGIoFGLEDGIMBwgIEEAAYA8ICCxAuGIoFGLEDGIMBwgILEAAYgAQYsQMYgwHCAhEQLhiDARjHARixAxjRAxiABMICDRAAGIoFGLEDGIMBGEPCAgcQABiKBRhDwgIIEAAYigUYsQPCAgUQABiABMICBxAAGIAEGArCAggQABjLARiABOIDBBgAIEGIBgGQBgg&sclient=gws-wiz-serp#fpstate=ive&ip=1&vld=cid:3a8db6d5,vid:qzkdP9z5DLY,st:0
import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head';
export default{
  setup(){
    const siteData = reactive({
      title: 'Esquénio',
      description : 'Esquénio - Estudos e projetos de engenharia'
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        },
        {
          name: `title`,
          content: computed(() => siteData.title)
        }
      ]
    })
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#app {
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html::-webkit-scrollbar, body::-webkit-scrollbar{
  display: none;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<style >

#app {
  font-family: 'Montserrat', sans-serif !important;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>