
<template>
    <div>
      <div class="padding">
        <div class="grid_container">
          <div>
            <h1 class="title">ATIVIDADE</h1>
            <p>
              Como empresa, surge em 2019 do seio do departamento de projeto do grupo CONDURIL contando com técnicos com mais de 15 anos de experiencia no projeto e execução de obras. <br/> Desde a sua criação a ESQUÉNIO assume uma estratégia centralizada 
              no rigor técnico e na inovação das soluções implementadas ou a 
              implementar, procurando através da orientação constante para o cliente, 
              a sua satisfação e a apresentação de soluções integradas, inovadoras e 
              sustentáveis.
            </p>
            <p>
              Prestamos ainda apoio técnico na apresentação de soluções variantes 
              no âmbito da execução de obra e elaboração de análises de risco no 
              âmbito da realização de empreitadas.
            </p>
          </div>
          <div class="logo_box">
            <img src="../../assets/About/logo-esquenio-branco-frase.png" alt="img_esquenio" style="width: 80%">
          </div>
        </div>
    
        <div class="grid_container reverse_columns">
          <div>
            <img src="../../assets/About/visao_img.png" alt="visao" style="width: 100%">
          </div>
          <div class="flex_end">
            <h1 class="title text_align">VISÃO</h1>
            <p>
              Capitalizamos a qualidade dos projetos que desenvolvemos, ao 
              orientarmo-nos de acordo com as caraterísticas do nosso cliente e, ao 
              otimizar as suas soluções, como empresa do grupo CONDURIL, de 
              acordo com os equipamentos que este possui, em especial para os 
              trabalhos de aplicação de pré-esforço, (trabalhando com os sistemas da 
              marca DSI - Dywidag Systems International) e na área da óleo-hidráulica 
              de elevação, dispondo de uma vasta gama de cilindros hidráulicos e 
              grupos de acionamento.
            </p>
              <ul class="pl-7">
                <li>Macacos mono-strand e multi-strand</li>
                <li>Enfiadoras e desenroladoras</li>
                <li>entral Hidráulica de Elevação Sincronizada até 16 Pontos independentes com monitorização e registo em tempo real de forças e deslocamentos </li>
                <li>Cilindros de haste oca de 20 a 60Tonf</li>
                <li>Cilindros de efeito simples de 25 a 600Tonf</li>
                <li>Grupos hidráulicos de 350bar e 700bar</li>
                <li>Bombas manuais e elétricas de 700bar</li>
              </ul>
          </div>
        </div>
    
        <div class="missao_text">
          <h1 class="title">MISSÃO</h1>
          <p>
            Prestação de serviços de consultoria e de elaboração de estudos e projetos nos 
            domínios da engenharia, incluindo estudos preliminares, estudos de viabilidade, 
            programas base, estudos prévios, anteprojetos e projetos de execução.
          </p>
        </div>
          
        <div class="grid_container" style="margin-top: 10rem">
            <img src="../../assets/About/ponte_sao_joao_1.jpg" alt="Ponte São João" style="width: 95%">
            <img src="../../assets/About/ponte_sao_joao_2.jpg"  alt="Ponte São João" style="width: 100%">
        </div>

        <div>
          <p style="margin-top: 10rem" class="missao_text">
            A ESQUÉNIO dispõe de ferramentas permanentemente atualizadas, 
            adequadas ao exercício da atividade de projeto. Além de folhas de 
            cálculo desenvolvidas internamente, utiliza os seguintes softwares:
          </p>
          <img src="../../assets/About/softwares.png" alt="softwares" style="width: 100%">
        </div>
      </div>

    
      <div class="gradient">
        <p class="recrutamento_text">
          Procuramos, constantemente, novos talentos e, nas 
          mais variadas áreas, que queiram fazer o seu percurso 
          profissional connosco. Envie-nos um e-mail com os seus 
          dados e CV atualizado para:
        </p>
        <img src="../../assets/About/team-01.png" alt="team" style="width: 100%">
        <p class="email">recursos.humanos@esquenio.pt</p>
      </div>
      </div>
    </template>
    
    <script>
    export default {
      name: 'HelloWorld',
    
    }
    </script>
    
    <style>
      .padding {
        padding: 5rem;
        overflow: hidden;
        width: 100wh;
    
        @media (max-width: 768px) {
          padding: 2rem;
        }
      }
    
      .grid_container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        text-align: justify;
        font-size: 1.2rem;
        gap: 4rem;
        margin-bottom: 10rem;
    
        @media (max-width: 768px) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
          gap: 2rem;
          margin-bottom: 5rem;
          margin-top: 3rem !important;
        }
      }
    
      .reverse_columns {
        @media (max-width: 768px) {
          display: flex !important;
          flex-direction: column-reverse !important;
        }
      }
    
      .title {
        font-size: 4rem;
        font-weight: 900;
        color: #0e2435;
        margin-top: 0;
        margin-bottom: 0.5rem;
    
        @media (max-width: 768px) {
          font-size: 3rem;
        }
      }
    
      .text_align {
        text-align: end;
    
        @media (max-width: 768px) {
          text-align: start;
        }
      }
    
      .logo_box {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0e2435;
        border-radius: 20px;
        min-height: 15rem;
      }
    
      .missao_text {
        font-size: 1.8rem;
        padding-inline: 7rem;
    
        @media (max-width: 768px) {
          padding-inline: 2rem;
          font-size: 1.2rem;
          margin-top: 5rem !important;
        }
      }
    
      .gradient {
        background: linear-gradient(to top, rgb(71, 92, 109), #ffffff);
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    
      .recrutamento_text {
        font-size: 1.8rem;
        padding-inline: 17rem;
        margin-bottom: 4rem;
        color: #0e2435;
        font-weight: 900;
    
        @media (max-width: 768px) {
          padding-inline: 2rem;
          font-size: 1.2rem;
        }
      }
    
      .email {
        font-size: 2.5rem;
        color: #0e2435;
        font-weight: 900;
    
        @media (max-width: 768px) {
          font-size: 1.2rem;
        }
      }
    </style>
    