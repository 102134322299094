<template>
  <v-container>
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-text-field
        label="Nome *"
        v-model="form.nome"
        :rules="nomeRules"
        required
        class="input"
      ></v-text-field>
        
      <v-text-field
        label="Email *"
        v-model="form.email"
        :rules="emailRules"
        required
        class="input"
      ></v-text-field>

      <v-text-field
        label="Outro Contacto (Opcional)"
        v-model="form.outroContacto"
      ></v-text-field>

      <v-text-field
        label="Tópico *"
        v-model="form.topico"
        :items="topicos"
        :rules="topicoRules"
        required
      ></v-text-field>

      <v-textarea
        label="Mensagem *"
        v-model="form.mensagem"
        :rules="mensagemRules"
        required
        rows="3"
        autosize
      ></v-textarea>

      <div class="buttons_wrapper">
      <v-btn @click="triggerFileUpload" prepend-icon="mdi-upload" class="bg-primary" style="width: 8rem">
        Upload
      </v-btn>
      <input
        type="file"
        ref="fileInput"
        style="display: none"
        @change="handleFileUpload"
      />
      <span v-if="fileName" style="margin-top: -0.5rem">{{ fileName }}</span>
    
      <v-btn type="submit" class="bg-primary" style="width: 8rem">Enviar</v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import Mailgun from 'mailgun.js';  

export default {
  data() {
    return {
      valid: false,
      form: {
        nome: "",
        email: "",
        outroContacto: "",
        topico: "",
        mensagem: "",
      },
      topicos: "",
      nomeRules: [
        (v) => !!v || "O nome é obrigatório",
        (v) => (v && v.length >= 3) || "O nome deve ter pelo menos 3 caracteres",
      ],
      emailRules: [
        (v) => !!v || "O email é obrigatório",
        (v) =>
          /.+@.+\..+/.test(v) || "O email deve ser um endereço de email válido",
      ],
      topicoRules: [(v) => !!v || "O tópico é obrigatório"],
      mensagemRules: [
        (v) => !!v || "A mensagem é obrigatória",
        (v) =>
          (v && v.length >= 10) ||
          "A mensagem deve ter pelo menos 10 caracteres",
      ],
      fileName: "",
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        
        const mailgun = new Mailgun(FormData); 

        const mg = mailgun.client( 

                { 

                    username: 'api',  

                    key: process.env.VUE_APP_MAILGUN_KEY, //'27d7d132f922d444cef9d1f0c0c38f13-c30053db-9af6b30e', 

                    url:"https://api.eu.mailgun.net" 

                } 

            ); 

        let message =   "Nova inscrição!<br/><br/>" +
                        "Nome: " + this.form.nome + "<br/><br/>" +
                        "Email: " + this.form.email + "<br/><br/>" +
                        "Outro: " + this.form.outroContacto + "<br/><br/>" +
                        "Tópico: " + this.form.topico + "<br/><br/>" + 
                        "Mensagem: " + this.form.mensagem + "<br/><br/>";

    
        const file = {
          filename: this.form.file.name,
          data: this.form.file
        };

        mg.messages.create('email.esquenio.pt', { 

          from: "info@esquenio.com", 

          to: ['geral@esquenio.pt'], //["aldamafonso@hotmail.com"], 

          subject: "Novo contacto Website", 

          text: "Novo contacto Website", 

          html: message,

          attachment: file

        }) 

        .then(msg => console.log(msg)) // logs response data 

        .catch(err => console.error(err)); // logs any error 

      } else {
        console.log("Erro na validação do formulário.");
      }
    },
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    async handleFileUpload(event) {
      const file = Array.from(event.target.files) 
      this.form.file = { name: file[0].name, file: file[0] };
      this.fileName = file ? file[0].name : "";
    },
  },
};
</script>
<style>
    .v-input__control {
        background-color:  rgba(71, 92, 109, 0.3) !important;
        border-bottom: 2px solid #fff;
        width: 25rem;
        height: 3.5rem; 
        padding: 0;
        box-shadow: none !important;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    .input .v-input__slot {
      height: 3.5rem; 
      padding: 0; 
    }

    form input:focus,
    form textarea:focus,
    form select:focus {
        background-color: rgba(71, 92, 109, 0.5); 
        border: none; 
        outline: none; 
    }


    .buttons_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
</style>
