import { createRouter, createWebHistory  } from 'vue-router'
import LayoutView from '../LayoutView.vue'
import HomeView from '@/views/HomeView.vue'
import ContactsView from '@/views/ContactsView.vue'
import AboutView from '@/components/about/AboutView.vue'

import PortfolioView from '@/views/PortfolioView.vue'
import DetailsView from '@/components/portfolio/DetailsView.vue'

const routes = [
  {
    path: '/',
    redirect: '/pt'
  },
  {
    path: '/:lang/home',
    component: LayoutView,
    children: [
      {
        path: '/:lang/',
        component: HomeView,
        name: "Homepage",
        meta: { title: "Homepage" }
      },
      {
        path: '/:lang/portfolio',
        component: PortfolioView,
        meta: { title: "Portfólio" }
      },
      {
        path: '/:lang/contacts',
        component: ContactsView,
        meta: { title: "Contacto" }
      },
      {
        path: '/:lang/home/:place',
        component: HomeView,
        name: "HomepagePlacing"
      },
      {
        path: '/:lang/portfolio/:id',
        component: DetailsView,
        name:"DetailsView",
        meta: { title: "Portfólio" }
      },
      {
        path: '/:lang/about',
        component: AboutView,
        name: 'AboutView',
        meta: { title: "Sobre" }
      }
    ],
  },
]

const router = createRouter({
  history: createWebHistory (),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "Esquénio"; 
  }
  next();
});

/*
router.beforeEach((to, from, next) => {
  i18n.locale = localStorage.getItem('lang') || 'pt'

  return next()
})*/

export default router
