import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/validations'

import '@/styles/main.scss'
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import {createI18n} from 'vue-i18n'
import messages from './locales'
import axios from "axios";
import EncryptValue from '@/plugins/encrypt'

import { createHead } from '@vueuse/head'
import VueCookies from 'vue-cookies'

const head = createHead()

import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader


const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#0e2435',
    'primary-darken-1': '#3700B3',
    secondary: '#475c6d',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#d4d4d4',
    success: '#4CAF50',
    warning: '#FB8C00',
    // Logo color
    accent: '#EA322A'
  },
}

axios.interceptors.request.use(
  function(config) {
        config.withCredentials = true;
        config.headers['Content-Type'] = "application/json";
        config.headers['Accept'] = "application/json";
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    router.push('/')
  }
  return Promise.reject(error)
})
  
const vuetify = createVuetify({
    components,
    directives,
    theme: {
      defaultTheme: 'myCustomLightTheme',
      themes: {
        myCustomLightTheme,
      },
    },
  });

export const i18n = new createI18n({
  locale: 'pt',
  fallbackLocale: 'pt',
  messages
})

const app = createApp(App)
.use(store)
.use(router)
.use(vuetify)
.use(i18n)
.use(head)
.use(VueCookies, { expires: '7d' });

app.config.globalProperties.$encryptValue = EncryptValue;

app.mount("#app");
