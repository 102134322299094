<template>
    <section v-if="!loading" class="d-flex align-center justify-center gradient-linear text-white" style="background-color: #d4d4d4;">
        <div class="my-10 py-10">
            <div :style="isMobile() ? '' : 'width: 50%; margin: auto;justify-content: center; align-items: center;'" :class="isMobile() ? 'mx-2 px-2' : ''">
                <v-row justify="center">
                    <v-col cols="12" class="text-h4 font-weight-black">
                        O que queremos partilhar consigo.
                    </v-col>   
                    <v-col cols="12" md="8" class="text-h6 font-weight-medium">
                        Desde 2019, a Esquénio possui o seu sistema integrado de gestão de qualidade, segurança e ambiente, certificado pela APCER.
                    </v-col>                    
                </v-row>
            </div>
            <v-row :class="'my-10 py-10' + isMobile() ? 'mx-2 px-2' : ' mx-10 px-10'" justify="center">
                <v-col cols="12" md="4" align="center">
                    <v-card :width="isMobile() ? '100%' : '350px'" height="250px" class="rounded-xl">
                        <v-img
                            :src="require('@/assets/Certificados/Certificado1.png')"
                            cover
                        />
                        <v-overlay
                            @click="openOverlay({ path: 'Certificado1.png' })"
                            v-model="overlay"
                            class="align-center justify-center text-white text-h4 font-weight-black"
                            style="cursor: pointer"
                            contained
                            :persistent="true"
                            :no-click-animation="true"
                        >
                            Qualidade
                        </v-overlay>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4" align="center">
                    <v-card :width="isMobile() ? '100%' : '350px'" height="250px" class="rounded-xl">
                        <v-img
                            :src="require('@/assets/Certificados/Certificado2.png')"
                            cover
                        />
                        <v-overlay
                            v-model="overlay"
                            @click="openOverlay({ path: 'Certificado2.png' })"
                            class="align-center justify-center text-white text-h4 font-weight-black"
                            style="cursor: pointer"
                            contained
                            :persistent="true"
                            :no-click-animation="true"
                        >
                            Segurança
                        </v-overlay>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4" align="center">
                    <v-card :width="isMobile() ? '100%' : '350px'" height="250px" class="rounded-xl">
                        <v-img
                            :src="require('@/assets/Certificados/Certificado3.png')"
                            cover
                        />
                        <v-overlay
                            @click="openOverlay({ path: 'Certificado3.png' })"
                            v-model="overlay"
                            class="align-center justify-center text-white text-h4 font-weight-black"
                            style="cursor: pointer"
                            contained
                            :persistent="true"
                            :no-click-animation="true"
                        >
                            Ambiente
                        </v-overlay>
                    </v-card>
                </v-col>
            </v-row>        
            <v-row  justify="center">
                <v-col cols="12" class="font-weight-black title_to_mobile" style="font-size: 2rem">
                    Conheça o nosso trabalho
                </v-col>
                <v-col cols="12" md="8" class="my-10 py-5 carousel_size">
                    <v-carousel :hide-delimiters="isMobile() ? false : true" class="rounded-xl" cycle :show-arrows="isMobile() ? false : true" >
                        <v-carousel-item
                            v-for="(item,i) in images_data"
                            :key="i"
                            :lazy-src='require("@/assets/portfolio/" + item.img_root)'
                            :src='require("@/assets/portfolio/" + item.img_root)'
                            reverse-transition="fade-transition"
                            cover
                            aspect-ratio="16/9"
                            transition="fade-transition"
                        ></v-carousel-item>
                    </v-carousel>
                </v-col>
            </v-row>    
        </div>

         <div v-if="showModal" class="modal-overlay" @click="closeModal">
            <div class="modal-content" @click.stop>
                <span class="close-button" @click="closeModal">&times;</span>
                <img :src="require(`@/assets/Certificados/${selectedImage.path}`)" class="modal-image" alt="Selected Image" />
            </div>
        </div>

    </section>
</template>
<script>
import portfolio from '@/portfolio-data/portfolio.json'

export default{
    name:"ShareWithYou",
    data(){
        return{
            loading: true,
            overlay: true,
            showModal: false,
            selectedImage: null,
            images_data: [],
            items: [
                {
                    src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
                },
                {
                    src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
                },
                {
                    src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
                },
                {
                    src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
                },
            ],
        }
    },
    mounted(){
        let object = portfolio.portfolio_data

        this.images_data = object

        this.images_data = this.images_data.slice(0, 4)


        this.loading = false
    },
    methods:{
        isMobile() {
            if( screen.width <= 760 ) {
                return true;
            }
            else {
                return false;
            }
        },
        openOverlay(place){
            this.selectedImage = place;
            this.showModal = true;
             
        },
        closeModal() {
            this.showModal = false;
            this.selectedImage = null;
        },
    }
}
</script>
<style>
.gradient-linear{
    background-image: linear-gradient(#0e2435, #eaeaea);
}

@media (max-width: 768px) {
    .title_to_mobile {
        font-size: 1.5rem !important;
    }
    .carousel_size {
        max-width: 80% !important;
        margin-inline: 2rem;
        padding: 0 !important;
    }
}

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .modal-content {
        position: relative;
    }

    .modal-image {
        border-radius: 1rem;
        width: auto; 
        height: 40rem; 
        object-fit: cover; 
        object-position: center; 

        @media (max-width: 768px) {
            width: 90%;
            height: auto;
        }
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 3rem;
        font-size: 30px;
        color: gray;
        cursor: pointer;
    }
</style>