<template>
    <section class="ma-0 pa-0">
        <v-container fluid class="fill-height ma-0 pa-0">            
            <v-img
                style="position: relative; filter: grayscale(100%);"
                cover
                :src="require('@/assets/PresentationView/old_bridge.jpg')"
                :lazy-src="require('@/assets/PresentationView/old_bridge.jpg')"
            />
        </v-container>
    </section>
</template>
<script>
export default{
    name:"ImageOnlyView"
}
</script>