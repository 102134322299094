<template>
    <section v-if="!loading" class="bg-info">
        <div class="container">             
            <div class="info_text">
                <p class="text-secondary">Contacte-nos para, juntos, <br/> darmos vida ao seu projeto</p>
                <img src="../assets/logo_azul.png" class="img_size"/>
            </div>
        </div>
            <img
                style="filter: grayscale(100%); " 
                class="background_image"
                :src="require('@/assets/PresentationView/old_bridge.jpg')"
                :lazy-src="require('@/assets/PresentationView/old_bridge.jpg')"
            />
            <div class="form_container">
            <div class="form" style="z-index: 1">
                <ContactForm/>
            </div>
        </div>
    </section>
</template>

<script>
import ContactForm from "./ContactForm.vue"
export default{
    components: {
        ContactForm
    },
    data(){
        return{
            loading: true
        }
    },
    mounted(){
        this.loading = false
    },
    methods:{
        submit(){
            
        }
    }
}
</script>
<style>
    
    .min-h-100-lg {
        height: 100vh;
        overflow: hidden;
    }

    .container {
        height: 20vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media (max-width: 768px) {
            height: 20vh;
        }
    }

    .background_image {
        width: 100%; 
        height: 57vh; 
        object-fit: cover; 
        object-position: center; 

        @media (max-width: 768px) {
            height: 70vh;
        }
    }

    .img_size {
        width: 50%;

        @media (max-width: 768px) {
            width: 70%;
        }
    }

    .info_text {
        text-align: end;
        padding-right: 10rem;
        font-size: 1.2rem;
        font-weight: 500;
        z-index: 1;
        position: relative;

        @media (max-width: 768px) {
            width: 100%;
            padding-right: 0;
            text-align: center;

        }
    }

    .form_container{
        position: absolute;
        z-index: 1;
        top: 0;
        margin-left: 3rem;
        margin-top: 5rem;

        @media (max-width: 768px) {
            margin-top: 16rem;
            display: flex;
            justify-content: center;
            margin-left: 0;
            width: 100%;
        }
    }

    .form {
        padding: 1rem;
        background-color: rgba(71, 92, 109, 0.9);
        color: #fff;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;


         @media (max-width: 768px) {
            padding: 0;
        }
    }
</style>