<template>
    <v-theme-provider>
        <v-app>
            <HeaderView @switchLocale="switchLocale" @openMenu="openMobileMenu = true;"/>
            <v-main>
                <router-view></router-view>
            </v-main>
            <FooterView />
        </v-app>
    </v-theme-provider>
</template>
<script>
import HeaderView from '@/components/header/HeaderView.vue'
import FooterView from '@/views/FooterView.vue'

export default{
    name: 'LayoutView',
    components: {
        HeaderView,
        FooterView
    },
    data(){
        return{
            openMobileMenu: false,
        }
    },
    mounted(){
        
    },
    methods:{
        switchLocale(l){
            this.$i18n.locale = l

            
            let newLanguage = this.$i18n.locale


            this.$router.push({name: this.$router.currentRoute._value.name, params: {lang: newLanguage}})
        },
        goTo(place){
            this.openMobileMenu = false; 
            this.$router.push('/' + this.$i18n.locale + '/' + place)
        }
    }
}
</script>
<style>

.custom-class {
    background: rgb(var(--v-theme-primary))
  }

</style>