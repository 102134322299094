<template>
    <section v-if="!loading" class="container_padding">

        <div style="width: 100%; text-align: start;" class="pb-10">
            <button class="back-button bg-primary" @click="goBack">
                <i class="fa fa-arrow-left"></i>
                <span class="ml-2">VOLTAR ATRÁS</span>
            </button>
        </div>
        
        <div class="grid_container reverse_columns">
            <div>
                <h1 v-if="fields && fields.title" class="title">{{ fields.title }}</h1>
                <h3 v-if="fields && fields.project.subtitle">{{ fields.project.subtitle }}</h3>
                <p v-if="fields && fields.project.description">{{ fields.project.description }}</p>
            </div>
            <div>
                <img v-if="fields && fields.img_root" :src="require(`@/assets/portfolio/${fields.img_root}`)" alt="Image" class="image-title" />
            </div>
        </div>


        <div v-if="fields && fields.project.imgs.length" class="grid_container_galeria">
            <div v-for="(img, index) in transformedImgs" :key="index">
                <div>
                    <div style="height: 10rem; overflow: hidden;">
                        <img 
                            v-if="img.path" 
                            :src="require(`@/assets/portfolio/${img.path}`)" 
                            alt="Image"  
                            class="image"
                            @click="openModal(img)"
                        />
                    </div>
                    <p v-if="img.title">{{ img.title }}</p>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div v-if="showModal" class="modal-overlay" @click="closeModal">
            <div class="modal-content" @click.stop>
                <span class="close-button" @click="closeModal">&times;</span>
                <img :src="require(`@/assets/portfolio/${selectedImage.path}`)" class="modal-image" alt="Selected Image" />
            </div>
        </div>


    </section>
</template>
<script>
import portfolio from '@/portfolio-data/portfolio.json'

export default {
    name: "DetailsView",
    data() {
        return {
            loading: true,
            fields: null,
            showModal: false, 
            selectedImage: null, 
            transformedImgs: [] 
        }
    },
    mounted() {
        const object = portfolio.portfolio_data
        const id = this.$route.params.id

        const mappedData = object.map(element => {
            return {
                ...element,
                transformedProperty: element.someProperty * 2
            }
        });

        this.fields = mappedData.find(element => element.id == id);

        if (this.fields.project && this.fields.project.imgs) {
            this.transformedImgs = this.fields.project.imgs.map(img => ({
                ...img,
                path: img.path,  
                title: img.title 
            }));
        }


        this.loading = false

    },
    methods: {
        openModal(image) {
            this.selectedImage = image;
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.selectedImage = null;
        },
        goBack() {
            this.$router.go(-1);
        }
    }
}
</script>
<style scoped>
    .container_padding {
        padding: 5rem;

        @media (max-width: 768px) {
            padding: 1rem;
        }
    }

    .back-button {
        padding: 0.5rem 2rem;
        border-radius: 1rem;
        font-weight: 500;
    }

    .title {
        text-transform: uppercase;
        color: #0e2435;
        font-size: 2rem;
    }

    .image-title {
        width: 100%; 
        height: 30rem; 
        object-fit: cover; 
        object-position: center; 
        border-radius: 10px;

        @media (max-width: 768px) {
            height: 20rem;
        }
    }

    .image {
        width: 100%; 
        height: 10rem; 
        object-fit: cover; 
        object-position: center; 
        border-radius: 10px;
        cursor: pointer;
    }

    .grid_container {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        text-align: justify;
        font-size: 1.2rem;
        gap: 4rem;
        margin-bottom: 10rem;
    
        @media (max-width: 768px) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
          gap: 2rem;
        }
      }

      .grid_container_galeria {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        text-align: justify;
        font-size: 1.2rem;
        gap: 2rem;
        margin-bottom: 10rem;
    
        @media (max-width: 768px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: 2rem;
        }
      }

      .reverse_columns {
        @media (max-width: 768px) {
          display: flex !important;
          flex-direction: column-reverse !important;
        }
      }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .modal-content {
        position: relative;
    }

    .modal-image {
        border-radius: 1rem;
        width: 60rem; 
        height: 30rem; 
        object-fit: cover; 
        object-position: center; 

        @media (max-width: 768px) {
            width: 95%;
            height: 20rem;
        }
    }

    .close-button {
        position: absolute;
        top: 0.5rem;
        right: 2rem;
        font-size: 30px;
        color: gray;
        cursor: pointer;
    }
</style>
